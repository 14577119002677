import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { languageFromString } from 'dg-web-shared/lib/Localized.ts';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { ALLOWED_LANGUAGE_CODES } from '../i18n/Text.ts';
import { LocalStorageBase } from 'dg-web-shared/lib/LocalStorageBase.ts';

export interface State {
    language: string;
}

const PARKINGPORTAL_LANG_KEY = 'parkingportal_language';

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'common-SettingsState';
    }

    sideEffects(_store: Flux.Store): void {
        // nop
    }

    getInitialState(): State {
        return {
            language: getDefaultLanguage() ?? 'de',
        };
    }

    writeLanguage(language: string): void {
        LocalStorageBase.setStringItem(PARKINGPORTAL_LANG_KEY, language);
        this.set((s: State) => {
            s.language = language;
            return s;
        });
    }

    writeLanguageFromServer(language: string): void {
        this.set((s: State) => {
            s.language = getDefaultLanguage() ?? language;
            return s;
        });
    }
}

function getDefaultLanguage(): string | null {
    const userLanguage: string | null =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (navigator.language || (window.navigator as any).userLanguage)
            .toString()
            .split('-')[0]
            .toLowerCase();
    const defaultBrowserLang = isValidLanguageCode(userLanguage)
        ? userLanguage
        : null;

    const localStorageLang = LocalStorageBase.getStringItem(
        PARKINGPORTAL_LANG_KEY,
    );

    const savedLanguage =
        isDefined(localStorageLang) && isValidLanguageCode(localStorageLang)
            ? localStorageLang
            : null;
    return savedLanguage ? savedLanguage : defaultBrowserLang;
}

function isValidLanguageCode(language: string | null): boolean {
    return (
        isDefined(language) && ALLOWED_LANGUAGE_CODES.indexOf(language) !== -1
    );
}

export function useOperatorLanguage() {
    const { storeState: language } = Flux.useStore(
        store => new StateSlice(store).state.language,
    );

    return languageFromString(language);
}
